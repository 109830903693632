<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Duplicated Links Logs" page-icon="mdi-link-variant">
    <g-list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        sort="id"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        stateendpoint="duplicatedLinksLog.duplicatedLinksLogs"
        @loadData="loadData"
        @destroyItem="destroyItem"
        @editItem="viewDetailsHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(links)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Remove all logs"
                icon="mdi-bookmark-remove"
                :toolbar="true"
                @onButtonClick="removedLogs({}, false)"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div class="pt-5 pb-3">
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
    <base-dialog
        title="Duplicated Links Log Details"
        icon="mdi-close"
        :dialog="dialog"
        :progress="progress"
        @closeDialogHandler="closeDialogHandler"
    >
      <v-container>
        <v-card v-for="(item, key) in duplicatedLinks" :key="key" class="mb-5">
          <v-card-title class="text-h5">
            Order ID: {{item.orderId}}
          </v-card-title>
          <v-card-subtitle>{{item.email}} <br> <strong>{{item.billingType}}</strong></v-card-subtitle>
          <v-card-text>{{item.liveLink}}</v-card-text>
        </v-card>
      </v-container>
    </base-dialog>
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import PageOverlay from "../../components/PageOverlay";
import GPSearch from "../../components/GPSearch";
import AddButton from "../../components/buttons/AddButton";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import BaseDialog from "../../components/dialog/BaseDialog";

export default {
  name: "DuplicatedLinksLogs",
  metaInfo: {
    title: "rabbiitfirm.com",
    titleTemplate: "Admin Dashboard - Duplicated Links Logs | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" }
    ]
  },
  components: {
    BaseDialog,
    ConfirmPopup,
    AddButton,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
  },
  mixins: [ListMixins],
  data() {
    return {
      dialog: false,
      progress: true,
      duplicatedLinks: [],
      headers: [
        {
          text: "Logs",
          value: "logs"
        },
        {
          text: "Error At",
          align: "right",
          value: "created_at",
        },
        {
          text: "Copy Link",
          value: "links",
        },
        {
          text: "",
          value: "actions",
          sortable: false
        },
      ],
      columns: [
        {
          name: "created_at",
          callback: "getDate"
        },
      ],
      actions: {
        load: "duplicatedLinksLog/load",
        destroy: "duplicatedLinksLog/destroy",
        clear: "duplicatedLinksLog/clear",
        view: "duplicatedLinksLog/get",
      },
      actionButton: [
        {
          category: "delete",
          icon: "mdi-delete",
          color: "error",
          text: "Delete",
          clickHandler: "destroyItem"
        },
        {
          category: "view",
          icon: "mdi-eye",
          color: "primary",
          text: "View Details",
          clickHandler: "editItem"
        },
      ]
    }
  },
  methods: {
    async viewDetailsHandler(item){
      try {
        this.dialog = true;
        let {data: {data: {items}}} = await this.$store.dispatch(this.actions.view, item);
        this.duplicatedLinks = items;
      } finally {
        this.progress = false;
      }
    },
    closeDialogHandler() {
      this.dialog = false;
    }
  }
}
</script>
